import React from "react";
// import "react-animated-slider/build/horizontal.css";
// import "../../assets/css/slider-animations.css";
// import "../../assets/css/style.css";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

import { logEvent, getAnalytics } from "firebase/analytics";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Icon } from "@iconify/react";
import phone from "../../constant/phone";
const content = [
  {
    title1_en: "You need a",
    title2_en: "car wash",
    description_en:
      "We have 11 years of experience in car wash for all types of cars, we will give you the best car wash ever.",
    title1_ar: "هل سيارتك بحاجة إلى عناية",
    title2_ar: "",
    description_ar:
      "نمتلك خبرة ١١ عام في العناية بجميع أنواع السيارات، ستحصل معنا على أفضل الخدمات  على الإطلاق",
    src: "images/img01.webp",
    type: "image",
  },
  {
    title1_en: "We made it",
    title2_en: "easy for you",
    description_en:
      "BlueTeam provides you with all services you need including car wash, polishing, and maintenance. All at one stop!!",
    title1_ar: "راحتكم تهمنا",
    title2_ar: "",
    description_ar:
      "بلو تيم تؤمن لك كل الخدمات التي تحتاجها من غسيل وتلميع وصيانة. كلها في مركز خدمات واحد باسعار مناسبة للجميع",
    src: "images/imgslider03.webp",
    type: "image",
  },
  {
    title1_en: "Anywhere",
    title2_en: "services",
    description_en:
      "As quickly as possible, we will reach you and deliver high-quality service for your car.",
    title1_ar: "في أي مكان",
    title2_ar: "",
    description_ar:
      "أينما كنت، سيصل إليك فريقنا بالسرعة القصوى لتقديم خدمات عالية الجودة لسيارتك. ",
    src: "images/imgslider05.webp",
    type: "image",
  },
];

const settings = {
  slider: "slider",
  previousButton: "previousButton",
  nextButton: "nextButton",
  buttonDisabled: "disabled",
  track: "track",
  slide: "slide",
  hidden: "hidden",
  previous: "previous",
  current: "current",
  next: "next",
  animateIn: "animateIn",
  animateOut: "animateOut",
};

function Banner({ setShow }) {
  const analytics = getAnalytics();
  const { t } = useTranslation();
  const margin = `${
    i18next.language === "ar"
      ? "fwEbold d-block font-weight-bold headingVI pt-3"
      : "fwEbold d-block font-weight-bold headingVI"
  }`;

  return (
    <section className="bannerSec bannerSlide">
      <div className="bsSlide bgCover overlay text-center position-relative d-flex">
        <Slider {...settings}>
          {content.map((slide, index) => (
            <div key={index}>
              {slide.type === "video" ? (
                <video
                  src={slide.src}
                  controls={false}
                  autoPlay={true}
                  width="100%"
                  muted
                  loop
                />
              ) : (
                <div>
                  <div
                    className="bsSlide bgCover overlay text-center position-relative d-flex"
                    style={{ backgroundImage: `url(${slide.src})` }}
                  >
                    <div className="alignCenter w-100 d-flex align-items-center">
                      <div className="container pt-90 pt-sm-10 pb-22 pt-md-12 pb-md-25 pt-lg-15 pb-lg-35">
                        <div className="caption text-white position-relative">
                          {i18next.language == "ar" ? (
                            <h2 className="w-100 mb-6 mb-md-9 text-white">
                              <strong className="fwEbold d-block mb-md-2 mb-xl-0">
                                {i18next.language == "ar"
                                  ? slide.title1_ar
                                  : slide.title1_en}
                              </strong>
                              <strong className="fwEbold d-block mb-3 mt-md-n4 mb-md-2">
                                {i18next.language == "ar"
                                  ? slide.title2_ar
                                  : slide.title2_en}
                              </strong>
                            </h2>
                          ) : (
                            <h1 className="w-100 mb-6 mb-md-9 text-white">
                              <strong className="fwEbold d-block mb-md-2 mb-xl-0">
                                {i18next.language == "ar"
                                  ? slide.title1_ar
                                  : slide.title1_en}
                              </strong>
                              <strong className="fwEbold d-block mb-3 mt-md-n4 mb-md-2">
                                {i18next.language == "ar"
                                  ? slide.title2_ar
                                  : slide.title2_en}
                              </strong>
                            </h1>
                          )}

                          <h3>
                            <strong
                              className={margin}
                              style={{ color: "#fff" }}
                            >
                              {i18next.language == "ar"
                                ? slide.description_ar
                                : slide.description_en}
                            </strong>
                          </h3>
                          <button
                            onClick={() => {
                              logEvent(analytics, "banner_booknow");
                              setShow(true);
                            }}
                            style={{
                              background: "#2196F3",
                              borderColor: "#2196F3",
                              color: "#fff",
                            }}
                            className="d-sm-block d-md-none btn  text-uppercase fwEbold py-lg-3 "
                          >
                            {t("book-Appoint")}
                          </button>
                          <br></br>
                          <a
                            type="button"
                            href={`tel:${phone}`}
                            className="btn btn-primary text-uppercase mt-3 fwEbold py-lg-3 py-lg-4 bg-white"
                            onClick={() => {
                              logEvent(analytics, "banner_callnow");
                              gtag_report_conversion(`tel:${phone}`);
                            }}
                          >
                            <span className=" mr-1 mr-lg-2">
                              <Icon fontSize={15} icon="ic:round-phone"></Icon>
                            </span>
                            {t("call_now")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Banner;
